console.log("bundle is connected");

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper from 'swiper/';
import Thumbs from 'swiper/modules/thumbs.min.mjs';
import EffectFade from 'swiper/modules/effect-fade.mjs';

window.Swiper = Swiper;
window.Thumbs = Thumbs;
window.EffectFade = EffectFade;

import Rellax from 'rellax';
window.Rellax = Rellax;

